.input {
  display: inline-block;
}

.input input {
  width: 25px;
  height: 25px;
}

.label {
  display: inline-block;
  margin: 0px 0px 0px 5px;
  position: relative;
  bottom: 5px;
}

.container:not(last-child) {
  margin: 0px 0px 10px 0px
}