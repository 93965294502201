.tabMenu {
  border-bottom: 1px solid #ddd;
}
.tabMenu ul {
  list-style-type: none;
  position: relative;
  text-align: center;
  padding-top: 10px;
}
.tabMenu ul li {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  font-size: 1.1rem;
  
  padding-bottom: 10px;
}
.tabMenu ul li:not(:first-child) {
  margin-left: 20px;
}
.tabMenu ul li:not(:last-child) {
  margin-right: 20px;
}

.tabActive {
  color: #23A492;
  border-bottom: 2px solid #23A492;
}

.tabContainer {
  margin: 25px 0px 0px 0px;
}