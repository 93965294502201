.button {
  display: table;
  text-align: center;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  width: 100%;
  height: 100%;
}

.loading {
  background-image: url("../images/Spin-1s-60px.gif");
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: 40px 40px;
}

.enabled {
  background-color: #DE6A59;
  cursor: pointer;
}
.disabled {
  background-color: grey;
}

.text {
  display: table-cell;
  font-size: 24px;
  vertical-align: middle;
  color: #FFFFFF;
}