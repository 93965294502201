.wrapper {
  height: 50px;
  position: relative;
}

.panel {
  transition: height 1s;
  position: absolute;
  background-color: #bde3de;
  /*border-bottom: 1px solid #23A492;*/
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 100%;
  overflow: hidden;
  padding: 0px 10px 0px 10px;
  box-shadow: 0 0 5px 2px rgba(0,0,0,.08);
  -webkit-box-shadow: 0 0 5px 2px rgba(0,0,0,.08);
  -moz-box-shadow: 0 0 5px 2px rgba(0,0,0,.08);
  z-index: 100;
  
}

.closedHeight {
  height: 50px;
}

.openHeight {
  height: 140px;
}

.openHeightWithCompany {
  height: 225px;
}

.top {
  display: table;
  width: 100%;
  
}

.item {
  display: table-cell;
  vertical-align: middle;
  position: relative;
}

.managerName {
  background-image: url("../images/manager-icon.png");
  background-position: 5px center;
  background-repeat: no-repeat;
  padding-left: 30px;
}

.menuIcon {
  width: 40px;
  right: 0px;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
}
.menuIconImageOpen {
  background-image: url("../images/open-menu-icon.png");
}
.menuIconImageClose {
  background-image: url("../images/close-menu-icon.png");
}