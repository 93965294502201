.inputContainer > div {
  margin: 0 0 20px 0;
}

.textInputRow > div {
  display: inline-block;
}

.textInputRow > div:first-child {
  width: 30%;
  margin-right: 2%;
}

.textInputRow > div:last-child {
  width: 68%;
}

.buttonContainer > div {
  height: 70px;
}

.buttonContainer > div:not(:last-child) {
  margin-bottom: 20px;
}

.navigationButtonContainer > div {
  display: inline-block;
  height: 70px;
}

.navigationButtonContainer > div:first-child {
  width: 30%;
  margin-right: 2%;
}

.navigationButtonContainer > div:last-child {
  width: 68%;
}

.hidden {
  display: none;
}

.itemContainer > div {
  margin-bottom: 20px;
}

.separatedItemContainer > div {
  margin-bottom: 20px;
}
.separatedItemContainer > div:not(:last-child) {
  border-bottom: 1px solid #ddd;
  padding-bottom: 20px;
}

.loadSpin {
  width: 300px;
  height: 300px;
  margin: auto;
  background-image: url("../images/loading.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 300px 300px;
}