.text {
    font-size: 18px;
}
.bold {
    font-weight: bold;
}
.normal {
    font-weight: normal;
}
.loading::after {
    /*content: "..." url("../images/Spin-1s-60px.gif");*/
    background-image: url('../images/loadspin-pine-60.gif');
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: right;
    display: inline-block;
    width: 45px; 
    height: 25px;
    content: '...'
}
.error {
    color: red
}
.hidden {
    display: none
}