.input {
  display: inline-block;
  margin: 0px 0px 0px 0px;
}
.input input {
  width: 25px;
  height: 25px;
}
.label {
  display: inline-block;
  position: relative;
  bottom: 5px;
  left: 5px;
}