.container {
    position: relative;
}

.textContainer {
    position: absolute;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    border: 1px solid #ddd;
    background-color: white;
    padding: 10px;
}

.visible {
    display: block;
}

.hidden {
    display: none;
}