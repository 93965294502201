* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
  
body {
  font-family: "Inter", Arial, sans-serif;
  line-height: 1.4;
  color: #1C3835;
  background: url("../images/background.jpg");
  height: 100vh;
}

h1 {
  color: #1C3835;
  font-size: 32px;
  padding: 0 0 20px 0;
  font-weight: 600;
}

a {
  text-decoration: none;
  color: #23A492;
}

.app-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 600px;
  margin: 0 auto;
  background-color: #EAE6D6;
  -moz-border-radius: 25px;
	-webkit-border-radius: 25px;
	border-radius: 25px;
  padding: 50px 50px 50px 50px;
  margin-top: 50px;
}

.app-container > div {
  width: 100%;
}