.input select {
    width: 100%;
    height: 70px;
    padding: 0 15px;
    font-size: 1rem;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    border-radius: 20px;
    border: none;
    background-color: #FFFFFF;
}

.title {
    margin: 0 0 5px 0;
}