.buttonContainer > div {
  display: inline-block;
  height: 70px;
}
  
.buttonContainer > div:first-child {
  width: 30%;
  margin-right: 2%;
}
  
.buttonContainer > div:last-child {
  width: 68%;
}

.errorMessageContainer {
  color: red;
}

.errorMessageContainer > div:not(:first-child) {
  margin-top: 5px;
}