.wrapper {
    margin-top: 50px;
    color: #1C3835;
}

.fixedWrapper {
    width: 115px;
    height: 60px;
    display: block;
    position: fixed;
    bottom: 10px;
    left: -20px;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    border-radius: 20px;
    padding: 10px 0px 0px 30px;
    background-color: #1C3835;
    color: #EAE6D6;
}

.content {
    display: inline-block;
    font-size: 10px;
}
.content > div:first-child {
    text-align: left;
}