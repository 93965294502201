.input {
    position: relative;
}
.input input {
    width: 100%;
    height: 70px;
    padding: 0 15px;
    font-size: 1rem;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    border-radius: 20px;
    border: none;
}

.title {
    margin: 0 0 5px 0;
}

.validated {
    /*background-color: #91d1c8*/
    background-image: url("../images/check-mark-gecko.svg");
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: 40px 40px;
}

.loading {
    background-image: url("../images/loadspin-pine-60.gif");
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: 40px 40px;
}

.error {
    background-color: red
}

.tooltip {
    position: absolute;
    top: 25px;
    right: -15px;
}